import React from 'react';
import { TypoXXXSBold } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import Image from 'next/image';
import styles from './css';

const EarlyBirdDiscountBadge = () => {
  const { vipRank } = useNeighbor();
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));

  const rate = campaigns?.early_bird?.discount?.rate_per_vip_rank?.[vipRank];

  if (!rate) {
    return null;
  }

  return (
    <div className="early-bird-discount-badge">
      <Image
        alt="icon-early-bird"
        height={16}
        src="/images/campaign/early-bird/badge.svg?v=3"
        width={16}
      />
      <TypoXXXSBold color="white" text={`${rate}%OFF`} />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default EarlyBirdDiscountBadge;
