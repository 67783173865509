import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .coin-info-nights-and-guests {
    display: block;
    :global(.vip-rank-badge) {
      display: inline-flex;
      margin: 4px 0;
    }
  }
  .coin-info-discount {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .coin-info-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
  }
  .coin-info-content {
    display: inline-block;
    text-decoration: line-through;
    font-size: 12px;
    color: ${COLORS.black600};
  }
  :global(.coin-info-nights-and-guests) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .vip-badge {
    background: ${COLORS.green500};
    border-radius: 4px;
    padding: 0 4px 2px;
  }
  .nights-and-guests {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .show-loading {
    display: flex;
    flex: 1;
    justify-content: start;
  }
`;

export default styles;
