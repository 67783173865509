import { useRouter } from 'next/router';

export const useIsEventProperty = (targetPropertyId?: number) => {
  const { query } = useRouter();

  return (
    process.env.NEXT_PUBLIC_EVENT_PROPERTY_ID ===
    (targetPropertyId?.toString() || query.id)
  );
};
