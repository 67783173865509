import React from 'react';
import IconHeartSvg from '@assets/icon-heart.svg';
import IconHeartOutline from '@assets/icon-heart-outline.svg';

type Props = {
  isFavored: boolean;
};

const IconHeartLine = ({ isFavored = false }: Props) => (
  <div>
    {isFavored ? <IconHeartSvg /> : <IconHeartOutline />}
  </div>
);

export default IconHeartLine;
