import React from 'react';
import EarlyBirdDiscountBadge from '@atoms/EarlyBirdDiscountBadge';
import { TypoSBold, TypoXXS, TypoXXXSBold } from '@components/atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import { useIsEventProperty } from '@hooks/useHafhEvent';
import { useNeighbor } from '@hooks/useNeighbor';
import IconCoin from '@svg-icons/IconCoin';
import { getCoinValue } from '@utils/coin';
import { VIP_DISCOUNT_RATE } from '@utils/constants';
import { useIsEarlyBirdActiveAndEligible } from '@utils/early-bird';
import { Coin } from '@utils/types';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  coin: Coin;
  nights?: number | null;
  noOfGuest?: number;
  propertyId?: number;
};

const CoinInfoWithGuestsAndNights = ({
  coin,
  nights,
  noOfGuest,
  propertyId,
}: Props): JSX.Element => {
  const isEarlyBirdActiveAndEligible = useIsEarlyBirdActiveAndEligible();

  const isEventProperty = useIsEventProperty(propertyId);
  const { loadingAuthUser } = useAppSelector((state) => ({
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { vipRank } = useNeighbor();
  const isVip = vipRank !== 'regular';

  const isCoinFetched = ![coin].every(
    (value) => value === undefined || value === null
  );
  const { t } = useTranslation('property');
  const guestsText =
    (noOfGuest &&
      `${t('planCalendar.guestNumberUnit', {
        guestNumber: noOfGuest,
      })} / `) ||
    '';

  const nightsText = t('stayUnit', {
    stay: nights || 1,
  });

  // Since it's shown during event apply term and it will be removed after that.
  if (isEventProperty) {
    return (
      <div
        className="coin-info-nights-and-guests"
        style={{ justifyContent: 'start' }}
      >
        <div className="coin-info-wrapper">
          <div
            className="coin-info-discount"
            style={{ alignItems: 'center', display: 'flex', marginTop: '4px' }}
          >
            <IconCoin size="sm" withFill={true} />
            <TypoSBold text=" 0" />
          </div>
        </div>
      </div>
    );
  }

  if (loadingAuthUser || !isCoinFetched) {
    return <Image alt="loading" height={8} src="/loading.gif" width={30} />;
  }

  return (
    <div className="coin-info-nights-and-guests">
      {(noOfGuest || nights) && (
        <div className="nights-and-guests">
          <TypoXXS color="black600" text={`${guestsText}${nightsText}`} />
        </div>
      )}
      <div className="coin-info-wrapper">
        <div className="coin-info-discount">
          <IconCoin size="sm" withFill={true} />
          <TypoSBold
            text={`${getCoinValue(
              Array.isArray(coin) ? coin[0] : coin,
              isVip || isEarlyBirdActiveAndEligible ? vipRank : 'base'
            )}~`}
          />
        </div>
        {(isVip || isEarlyBirdActiveAndEligible) && (
          <>
            <div className="coin-info-content">
              <IconCoin color="black600" size="xxs" withFill={false} />
              {getCoinValue(coin, 'base')}~
            </div>
            {isEarlyBirdActiveAndEligible ? (
              <EarlyBirdDiscountBadge />
            ) : (
              isVip && (
                <div className="vip-badge">
                  <TypoXXXSBold
                    color="white"
                    text={`${VIP_DISCOUNT_RATE[vipRank]}%OFF`}
                  />
                </div>
              )
            )}
          </>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CoinInfoWithGuestsAndNights;
