import { Coin, VipRank } from '@utils/types';

export const getCoinValue = (
  coin: Coin | Coin[] | undefined,
  vipRank: VipRank | 'base',
  hideMaxCoins?: boolean
) => {
  if (Array.isArray(coin)) {
    if (coin.length === 1) {
      return coin[0][vipRank];
    }

    if (hideMaxCoins) {
      return `${coin[0][vipRank]} ~`;
    }

    return `${coin[0][vipRank]} ~ ${coin[coin.length - 1]?.[vipRank]}`;
  }

  return coin?.[vipRank];
};
