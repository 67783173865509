import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .early-bird-discount-badge {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    background: ${COLORS.earlyBirdPrimary};
  }
`;

export default styles;
