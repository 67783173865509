/* eslint-disable */
import css from 'styled-jsx/css';

const styles = css`
  .favorite-button {
    cursor: pointer;
  }
`;

export default styles;
